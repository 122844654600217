import { ChangeEvent, useState } from "react";
import { Button } from "../Button/Button.component";
import { Checkbox } from "../Checkbox/Checkbox.component"
import { Input } from "../Input/Input.component";

import './ExportGroupsFilter.style.scss';

type Props = {
    onClick: (quantityPerGroup: number, separateSmokers: boolean, separateGender: boolean) => void
}

export const ExportGroupsFilter = ({ onClick }: Props) => {
    const [separateGender, setSeparateGender] = useState<boolean>(false)
    const [separateSmoker, setSeparateSmoker] = useState<boolean>(false)
    const [quantityPerGroup, setQuantityPerGroup] = useState<string>('3')

    const handleChangeGender = (event: any) => setSeparateGender(lastValue => !lastValue)
    
    const handleChangeSmoker = (event: any) => setSeparateSmoker(lastValue => !lastValue)

    const handleChangeQuantityPerGroup = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setQuantityPerGroup(event.target.value)
    }

    const handleClickButton = () => {
        onClick(parseInt(quantityPerGroup), separateSmoker, separateGender)
    }

    return (
        <div className="export-groups-filter__container">
            <Input label="N° integrantes por grupo:" type="number" onChange={handleChangeQuantityPerGroup} value={quantityPerGroup} />
            <div className="export-groups-filter__container__separate">
                <span>Separar por:</span>
                <div className="export-groups-filter__container__separate__options">
                    <Checkbox checked={separateGender} label="Genêro" onChange={handleChangeGender} />
                    <Checkbox checked={separateSmoker} label="Fumante" onChange={handleChangeSmoker} />
                </div>
            </div>
            <Button type="button" name="Exportar" onClick={handleClickButton} />
        </div>
    )
};