export default (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="48px" height="48px" viewBox="0 0 386.000000 367.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,367.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M1113 2865 c-89 -25 -174 -74 -239 -139 -104 -105 -148 -217 -148
    -386 0 -169 42 -279 147 -384 71 -71 132 -107 226 -136 88 -28 240 -28 331 -2
    171 51 304 182 358 356 21 69 21 263 0 332 -51 163 -153 274 -311 338 -63 26
    -87 30 -192 33 -84 2 -136 -2 -172 -12z m287 -222 c68 -31 134 -95 165 -160
    34 -73 38 -181 9 -263 -24 -67 -103 -152 -171 -182 -75 -34 -209 -31 -285 5
    -66 32 -120 89 -154 161 -19 41 -24 67 -24 138 0 78 3 92 33 149 38 74 102
    131 178 159 69 26 183 23 249 -7z"/>
    <path d="M1882 2873 l-33 -4 36 -48 c45 -62 99 -172 119 -241 35 -126 35 -337
    0 -474 -18 -70 -63 -163 -110 -227 -19 -26 -34 -51 -34 -57 0 -30 234 -26 330
    5 123 40 235 135 289 245 34 69 61 190 61 273 0 78 -28 202 -61 268 -59 116
    -190 215 -334 252 -55 14 -182 18 -263 8z"/>
    <path d="M2992 2519 c-125 -15 -259 -106 -325 -219 -55 -95 -71 -228 -41 -343
    20 -77 53 -135 113 -194 104 -104 192 -138 346 -131 89 4 108 8 168 38 l68 33
    185 -184 184 -184 50 50 c28 27 49 53 48 57 -2 4 -59 65 -128 135 -69 70 -149
    153 -178 184 l-52 56 30 59 c81 159 49 367 -78 505 -98 107 -237 156 -390 138z
    m188 -174 c62 -32 105 -72 136 -127 26 -48 29 -62 29 -143 0 -102 -17 -147
    -82 -211 -134 -134 -379 -98 -464 68 -83 162 -10 361 153 420 66 23 176 20
    228 -7z"/>
    <path d="M1090 1559 c-136 -12 -236 -34 -395 -86 -336 -109 -449 -174 -518
    -297 -50 -90 -57 -132 -57 -353 l0 -203 1145 0 1145 0 0 210 c0 238 -6 267
    -82 381 -50 74 -106 113 -250 173 -371 155 -651 205 -988 175z m435 -224 c137
    -19 234 -47 405 -116 231 -94 269 -138 270 -311 l0 -68 -936 0 -936 0 4 93 c7
    160 41 197 265 285 182 72 261 95 393 115 125 20 407 21 535 2z"/>
    <path d="M2323 1520 c112 -74 203 -167 239 -247 63 -141 68 -169 75 -420 l6
    -233 329 0 330 0 -4 223 -3 223 -38 76 c-81 165 -256 272 -577 353 -73 19
    -385 75 -415 75 -11 0 11 -20 58 -50z"/>
    </g>
    </svg>
)
