export default (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="48px" height="48px" viewBox="0 0 368.000000 368.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,368.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M950 3225 c-77 -21 -114 -50 -146 -113 l-29 -57 -3 -1287 c-2 -1149
    -1 -1288 13 -1288 8 0 34 9 58 19 23 11 155 68 292 126 138 58 259 110 270
    115 83 40 422 180 436 180 16 0 102 -34 217 -86 29 -13 55 -24 57 -24 2 0 30
    -12 62 -26 32 -14 73 -32 91 -40 82 -35 100 -43 142 -62 25 -11 117 -50 205
    -87 88 -37 184 -78 213 -91 29 -13 59 -24 67 -24 13 0 15 83 13 658 l-3 657
    -107 3 -108 3 -2 -493 -3 -493 -21 2 c-12 1 -95 33 -185 71 -90 38 -220 93
    -289 122 -69 29 -143 60 -165 70 -87 39 -169 70 -185 70 -16 0 -98 -31 -185
    -70 -22 -10 -96 -41 -165 -70 -69 -29 -199 -84 -289 -122 -90 -38 -173 -70
    -185 -71 l-21 -2 0 1095 0 1095 498 3 498 2 -3 113 -3 112 -495 1 c-369 1
    -506 -2 -540 -11z"/>
    <path d="M2316 3079 c-108 -18 -231 -103 -296 -206 -81 -128 -83 -330 -5 -458
    68 -110 186 -193 310 -216 102 -19 238 5 309 54 l28 20 187 -187 186 -186 53
    53 52 52 -180 180 c-99 99 -180 185 -180 192 0 7 9 29 20 50 26 52 50 148 50
    203 -1 124 -48 239 -135 325 -109 110 -241 151 -399 124z m240 -184 c91 -53
    138 -141 138 -255 0 -155 -91 -265 -237 -288 -106 -17 -201 13 -268 85 -63 65
    -74 96 -74 203 0 73 4 103 19 130 25 48 84 108 128 131 82 42 216 39 294 -6z"/>
    </g>
    </svg>
)