import { ICONS } from '../../assets/svgs';
import { Icon } from '../Icon/Icon.component';
import './ExportModeLine.style.scss';

type Props = {
    icon: keyof typeof ICONS,
    title: string,
    onClick: () => void,
    disabled?: boolean
}

export const ExportModeLine = ({ icon, title, onClick, disabled = false }: Props) => {

    return (
        <button type='button' onClick={onClick} disabled={disabled} className='export-mode-line__container'>
            <Icon name={icon} classname='' />
            { disabled ? `${title} (indisponível)` : title }
        </button>
    )
};