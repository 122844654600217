export default (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="48px" height="48px" viewBox="0 0 413.000000 367.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,367.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M1131 2869 c-80 -15 -184 -72 -248 -135 -107 -106 -156 -227 -157
    -389 -1 -164 47 -288 150 -392 105 -104 229 -153 391 -153 156 0 282 48 380
    146 111 111 157 223 157 389 0 167 -37 271 -139 382 -64 71 -180 136 -274 153
    -64 12 -198 11 -260 -1z m253 -220 c74 -27 147 -94 181 -166 24 -50 28 -70 28
    -143 -1 -123 -46 -212 -141 -274 -132 -87 -326 -67 -426 44 -65 72 -86 128
    -86 228 0 112 22 166 96 238 93 91 226 119 348 73z"/>
    <path d="M1888 2873 c-16 -2 -28 -7 -28 -11 0 -4 16 -30 36 -57 111 -154 155
    -353 129 -579 -18 -146 -47 -224 -133 -353 -19 -29 -32 -57 -28 -61 4 -4 65
    -7 134 -7 99 0 139 4 182 19 175 60 291 181 335 347 39 145 25 311 -37 438
    -58 119 -187 219 -333 256 -55 14 -190 18 -257 8z"/>
    <path d="M3181 2703 c-7 -54 -14 -106 -17 -115 -3 -9 -43 -35 -88 -57 -46 -22
    -98 -52 -115 -67 l-32 -26 -94 23 c-52 12 -96 21 -99 18 -2 -2 -22 -35 -45
    -72 l-42 -69 82 -76 82 -77 0 -94 0 -95 -82 -75 -81 -76 41 -67 c24 -39 48
    -68 58 -68 9 0 53 7 98 16 79 16 84 16 109 -2 75 -53 113 -75 157 -90 26 -8
    47 -16 48 -17 0 -1 8 -54 17 -117 l17 -115 91 -3 92 -3 5 33 c3 18 11 70 18
    116 l11 82 77 36 c42 19 93 49 115 66 l39 31 93 -17 c51 -10 97 -15 102 -12
    13 8 83 127 80 136 -2 4 -37 38 -78 76 l-75 69 -1 95 0 95 80 76 80 76 -42 71
    c-24 40 -47 72 -53 72 -5 0 -47 -9 -93 -21 l-82 -21 -60 41 c-32 23 -86 52
    -119 66 l-60 24 -13 83 c-7 46 -15 98 -18 116 l-5 32 -93 0 -93 0 -12 -97z
    m253 -361 c91 -49 135 -130 135 -252 1 -171 -111 -281 -287 -281 -149 0 -259
    97 -278 247 -11 90 33 212 96 261 83 66 236 78 334 25z"/>
    <path d="M1107 1559 c-175 -14 -346 -56 -552 -134 -257 -98 -330 -150 -394
    -285 l-36 -75 -3 -223 -4 -222 1144 2 1143 3 3 203 c2 190 1 208 -20 265 -32
    88 -90 169 -151 211 -74 51 -283 135 -472 191 -116 34 -225 54 -355 65 -128
    11 -161 11 -303 -1z m488 -232 c103 -20 186 -46 326 -103 151 -61 182 -78 223
    -120 40 -41 56 -98 56 -199 l0 -65 -935 0 -935 0 0 83 c0 58 6 94 19 123 35
    77 74 104 247 172 291 114 392 133 704 128 139 -3 243 -9 295 -19z"/>
    <path d="M2326 1519 c153 -100 233 -203 283 -364 19 -60 23 -102 28 -302 l6
    -233 328 0 329 0 0 198 c0 152 -4 211 -16 253 -61 207 -251 339 -610 424 -100
    24 -382 75 -412 75 -8 0 21 -23 64 -51z"/>
    </g>
    </svg>
)
