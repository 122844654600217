import { openWindowWithPdf } from "../../utils/PdfUtils"
import { api } from "../api"

export const useGetExportGroups = () => {

    const getExportGroups = async (id: string, quantityPerGroup: number, separateSmokers: boolean, separateGender: boolean) => {
        const response = await api.get('class/export-groups', 
            { 
                params: { classId: id, quantityPerGroup: quantityPerGroup, separateSmokers: separateSmokers, separateGender: separateGender }, 
                headers: { "Content-Type": "application/pdf" },
                responseType: 'blob'
            })
        
        openWindowWithPdf(response)
    }

    return ({
        call: getExportGroups
    })
}