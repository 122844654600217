import { AxiosResponse } from "axios";

export const openWindowWithPdf = (response: AxiosResponse) => {
    const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
    
    const fileURL = URL.createObjectURL(file);

    window.open(fileURL);
}