import { openWindowWithPdf } from "../../utils/PdfUtils"
import { api } from "../api"

export const useGetExportCertificatesByClassId = () => {

    const getExportCertificatesByClassId = async (id: string) => {
        const response = await api.get('class/export-certificates', 
            { 
                params: { classId: id }, 
                headers: { "Content-Type": "application/pdf" },
                responseType: 'blob'
            })

        openWindowWithPdf(response)
    }

    return ({
        call: getExportCertificatesByClassId
    })
}