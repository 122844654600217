export default (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="48px" height="48px" viewBox="0 0 368.000000 368.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,368.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M2691 3320 l-13 -90 -63 -30 c-36 -17 -77 -41 -93 -55 l-28 -23 -77
    17 -76 18 -35 -58 -35 -59 65 -57 64 -58 0 -76 0 -77 -64 -60 -65 -60 35 -56
    c20 -31 38 -56 40 -56 3 0 36 7 73 16 l69 15 62 -40 c34 -22 76 -45 95 -51 32
    -11 33 -12 46 -103 l14 -92 71 -3 c82 -3 77 -9 90 105 8 74 15 85 73 107 25
    10 62 31 83 48 l38 30 76 -17 c42 -9 77 -15 79 -13 1 1 18 27 36 57 l33 55
    -64 57 -64 58 0 77 -1 76 63 59 c34 32 62 60 62 63 0 17 -66 106 -79 106 -9 0
    -43 -7 -75 -14 -58 -14 -61 -14 -91 9 -17 14 -58 36 -90 51 -33 15 -61 28 -63
    29 -1 1 -7 31 -13 66 -20 130 -11 119 -93 119 l-72 0 -13 -90z m221 -285 c77
    -54 109 -168 76 -277 -15 -50 -83 -113 -140 -128 -52 -15 -134 -8 -182 15
    -117 56 -152 243 -65 348 51 61 91 78 186 75 72 -3 86 -6 125 -33z"/>
    <path d="M948 3225 c-76 -21 -112 -49 -144 -112 l-29 -58 -3 -1284 c-1 -707 1
    -1288 5 -1292 5 -4 26 1 48 11 22 10 121 53 220 95 99 42 204 88 233 101 29
    13 55 24 57 24 2 0 34 14 70 30 36 17 68 30 70 30 2 0 28 11 57 24 29 13 98
    42 153 65 55 23 108 46 117 51 22 13 53 13 76 0 9 -5 62 -28 117 -51 55 -23
    124 -52 153 -65 29 -13 55 -24 57 -24 2 0 34 -14 70 -30 36 -17 68 -30 70 -30
    2 0 28 -11 57 -24 29 -13 103 -45 163 -71 61 -26 134 -58 163 -71 29 -13 55
    -24 57 -24 2 0 29 -11 59 -25 30 -14 57 -25 60 -25 4 0 5 363 4 808 l-3 807
    -105 0 -105 0 -5 -635 -5 -635 -22 2 c-13 2 -44 12 -70 24 -59 26 -66 29 -293
    124 -182 76 -218 91 -282 119 -110 47 -160 66 -176 66 -18 0 -100 -31 -187
    -70 -22 -10 -78 -34 -125 -53 -142 -58 -333 -138 -376 -158 -91 -41 -148 -59
    -156 -51 -4 4 -7 498 -6 1098 l3 1089 555 5 555 5 0 110 0 110 -555 2 c-428 1
    -566 -2 -602 -12z"/>
    </g>
    </svg>
)