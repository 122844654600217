import { ExportModeLineProp } from '../../models';
import { ExportModeLine } from '../ExportModeLine/ExportModeLine.component';
import './ModalExportModes.style.scss';

type Props = {
    options: Array<ExportModeLineProp>
}

export const ModalExportModes = ({ options }: Props) => {
    return (
        <div className="modal-export-modes__container">
            { options.map(({ icon, title, onClick, disabled }, index) => <ExportModeLine key={`export-modal-line-${index}`} icon={icon} title={title} onClick={onClick} disabled={disabled} />) }
        </div>
    )
};