import { openWindowWithPdf } from "../../utils/PdfUtils"
import { api } from "../api"

export const useGetExportClients = () => {

    const getExportClients = async (id: string, withMoney: boolean) => {
        const response = await api.get('class/export-clients', 
            { 
                params: { classId: id, withMoney: withMoney }, 
                headers: { "Content-Type": "application/pdf" },
                responseType: 'blob'
            })

        openWindowWithPdf(response)
    }

    return ({
        call: getExportClients
    })
}