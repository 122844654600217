import { ICONS } from "../assets/svgs"

type ExportModes = {
    icon: keyof typeof ICONS,
    title: string
}

type ExportModesClass = {
    EXPORT_CERTIFICATES: ExportModes,
    EXPORT_CLIENTS_ALPHABETICAL_ORDER_AND_TOTAL_PAYMENT: ExportModes,
    EXPORT_CLIENTS_IN_GROUPS: ExportModes,
    EXPORT_CLIENTS_WITH_OBSERVATION: ExportModes,
    EXPORT_CLIENTS_WITH_PHONE_NUMBER: ExportModes,
    EXPORT_BADGE_CLIENTS: ExportModes,
}

export const EXPORTS_MODES_CLASS: ExportModesClass = {
    EXPORT_CERTIFICATES: { icon: "License", title: "Certificados" },
    EXPORT_CLIENTS_ALPHABETICAL_ORDER_AND_TOTAL_PAYMENT: { icon: "AlphabeticalOrder", title: "Clientes (ABC/Pagamento total)" },
    EXPORT_CLIENTS_IN_GROUPS: { icon: "Group", title: "Clientes em grupos" },
    EXPORT_CLIENTS_WITH_OBSERVATION: { icon: "Bookmark", title: "Clientes c/ campo observação" },
    EXPORT_CLIENTS_WITH_PHONE_NUMBER: { icon: "Bookmark", title: "Clientes c/ telefone" },
    EXPORT_BADGE_CLIENTS: { icon: "Badge", title: "Crachás" },
}