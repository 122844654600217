import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";

type Notification = {
    key: string,
    message: string
}

export const api : AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: { "Content-Type": 'application/json' }
})

export const registerInterceptors = () => {

    api.interceptors.response.use(
        function (response) {
            return response;
        }, async function (error) {
            const { response } = error
            
            if (error.config.responseType === 'blob') {
                var test = new Blob([error.response.data], { type: "application/json" })
                response.data = JSON.parse(await test.text())
            }

            toast.error(response.data.message)

            const notifications: Array<Notification> = response.data.notifications
            if (notifications?.length > 0) {
                notifications.forEach(notification => toast.error(notification.message))
            } else {  }
            
            return Promise.reject(error);
        }
    )
}
