import { useState } from 'react';
import { Button } from '../Button/Button.component';
import { Checkbox } from '../Checkbox/Checkbox.component';
import './ExportClientsFilter.style.scss';

type Props = {
    onClick: (withMoney: boolean) => void
}

export const ExportClientsFilter = ({ onClick }: Props) => {
    const [withMoney, setWithMoney] = useState<boolean>(false)

    const handleChangeWithMoney = (event: any) => setWithMoney(lastValue => !lastValue)

    const handleClickButton = () => {
        onClick(withMoney)
    }

    return (
        <div className="export-clients-filter__container">
            <div className="export-clients-filter__container__money">
                <span>Exportar campo valor total?</span>
                <div className="export-clients-filter__container__money__options">
                    <Checkbox checked={withMoney} label="Sim" onChange={handleChangeWithMoney} />
                </div>
            </div>
            <Button type="button" name="Exportar" onClick={handleClickButton} />
        </div>
    )
}